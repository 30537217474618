import "bootstrap/dist/css/bootstrap.css";

import "shards-ui/dist/css/shards.min.css";

import "react-table-6/react-table.css";

import "./style.scss";

import "lib/index.scss";

import Css from "./style.module.scss";

import { ImageViewer, ModalWindow } from "lib/common";
import { getModalImages, getModalWindowConfig } from "selectors/ui";
import { useSelector } from "react-redux";
import GlobalTooltip from "nlib/common/GlobalTooltip";
import ModalsContainer from "./lib/ModalsContainer";
import React, { Suspense } from "react";
import Routes from "./lib/Routes";

const App = () => {
  const modalWindowConfig = useSelector(getModalWindowConfig);

  const modalImages = useSelector(getModalImages);

  return (
    <>
      <div
        className={`${Css.app} app`}>
        <Routes />
        <Suspense fallback={null}>
          <ModalsContainer />
        </Suspense>
      </div>
      <GlobalTooltip />
      {modalImages && <ImageViewer sources={modalImages} />}
      {modalWindowConfig && <ModalWindow appModal config={modalWindowConfig} />}
    </>
  );
};

export default React.memo(App);
